<template>
  <form method="POST" action="https://sogecommerce.societegenerale.eu/vads-payment/" accept-charset="UTF-8">
    <input type="hidden" name="vads_action_mode" :value="formData.vads_action_mode" />
    <input type="hidden" name="vads_amount" :value="formData.vads_amount"  />
    <input type="hidden" name="vads_ctx_mode" :value="formData.vads_ctx_mode"  />
    <input type="hidden" name="vads_currency" :value="formData.vads_currency"  />
    <input type="hidden" name="vads_page_action" :value="formData.vads_page_action"  />
    <input type="hidden" name="vads_payment_config" :value="formData.vads_payment_config" />
    <input type="hidden" name="vads_site_id" :value="formData.vads_site_id"  />
    <input type="hidden" name="vads_trans_date" :value="formData.vads_trans_date" />
    <input type="hidden" name="vads_trans_id" :value="formData.vads_trans_id" />
    <input type="hidden" name="vads_version" :value="formData.vads_version" />
    <input type="hidden" name="vads_order_id" :value="formData.vads_order_id" />
    <input type="hidden" name="vads_order_info" :value="formData.vads_order_info" />
    <input type="hidden" name="vads_cust_id" :value="formData.vads_cust_id" />
    <input type="hidden" name="signature" :value="formData.signature" />
    <input type="submit" name="pay" value="Pay" />
  </form>
</template>
<script>
import {extend} from "vee-validate";
import {mapGetters} from "vuex";
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import EnglishStep from "./FormWizard/EnglishStep";
import OtherLanguagesStep from "./FormWizard/OtherLanguagesStep";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormWizard,
    TabContent,
    OtherLanguagesStep,
    EnglishStep,
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {},
      defaultFormData: {
        category_id: "",
        title: "",
        url: "",
        brief: "",
        image: "",
        content: "",
        difficulty: 0,
        prerequisites: [],
        teaching_methods: [],
        after_training: [],
        goals: [],
        scientific_programmes: [],
        contributors: [],
        gallery: [],
        gallery2: [],
        cost: 0,
        is_active: true,
        is_featured: false,

      },

      builderData: {
        categoryList: [],
        teamList: [],
      },
      wizardModel: {}

    };
  },

  computed: {
    ...mapGetters({
      languageList: "global/languageList"
    })
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.templateForm
    });
    this.setNullFormData();

    let data = {}
    this.editMode = true;
    this.formTitle = "Edit Training";
    this.getItem();
  },


  methods: {
    getItem() {
      let data = {
        session_id: 155
      }
      this.axios.post("http://127.0.0.1:8000/front/trainings/book-session", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        console.log(error)
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Training Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },

    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      if (this.editMode === true) {
        request = this.axios.put("trainings/update/" + this.id, this.formData);
        successMessage = "Training Updated Successfully";
      } else {
        request = this.axios.post("trainings/create", this.formData);
        successMessage = "Training Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/trainings/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
          this.$refs.panelFormWizard.navigateToTab(0);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    validateStep(ref) {
      if (Array.isArray(this.$refs[ref])){
        return this.$refs[ref][0].validate();
      }else{
        return this.$refs[ref].validate();
      }
    },
    onStepValidated(validated, model) {
      this.wizardModel = {...this.wizardModel, ...model}
    },
    submitStep(data, lang, isSubmit){
      this.formData[lang] = data;
      if (isSubmit){
        this.submit();
      }
    },
    setNullFormData(){
      this.defaultFormData['en'] = {
        name: '',
        ident: '',
      };
      this.languageList.forEach(lang => {
        this.defaultFormData[lang] = {
          name: '',
          ident: '',
        }
      })
    },
  }
}
</script>
