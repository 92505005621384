var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage'](_vm.lang.label))+" "+_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Section')))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.title","rules":"","name":"The Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Title'),"name":"title","fou":""},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})]}}])})],1)]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(this.$store.getters['languageMixin/getStringFromLanguage']('Brief')))]),_c('editor',{attrs:{"api-key":_vm.editorKey,"init":_vm.editorConfig},model:{value:(_vm.formData.brief),callback:function ($$v) {_vm.$set(_vm.formData, "brief", $$v)},expression:"formData.brief"}})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Content')))]),_c('editor',{attrs:{"api-key":_vm.editorKey,"init":_vm.editorConfig},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.prerequisites","rules":"","name":"The Prerequisites"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-text',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Prerequisites'),"name":"prerequisites","fou":""},model:{value:(_vm.formData.prerequisites),callback:function ($$v) {_vm.$set(_vm.formData, "prerequisites", $$v)},expression:"formData.prerequisites"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.audience_target","rules":"","name":"The Audience"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-text',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Audience'),"name":"audience_target","fou":""},model:{value:(_vm.formData.audience_target),callback:function ($$v) {_vm.$set(_vm.formData, "audience_target", $$v)},expression:"formData.audience_target"}})]}}])})],1)]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(this.$store.getters['languageMixin/getStringFromLanguage']('Notes')))]),_c('editor',{attrs:{"api-key":_vm.editorKey,"init":_vm.editorConfig},model:{value:(_vm.formData.notes),callback:function ($$v) {_vm.$set(_vm.formData, "notes", $$v)},expression:"formData.notes"}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Teaching Methods List')))]),_c('div',{staticClass:"col-12"},[_vm._l((_vm.formData.teaching_methods),function(listItem,listItemIndex){return [_c('div',{staticClass:"row team_list_items"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Item'))+" # "+_vm._s(listItemIndex + 1))]),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.teaching_methodstitle","rules":"","name":"The Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{key:'title_'+listItemIndex,attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)},model:{value:(_vm.formData.teaching_methods[listItemIndex].title),callback:function ($$v) {_vm.$set(_vm.formData.teaching_methods[listItemIndex], "title", $$v)},expression:"formData.teaching_methods[listItemIndex].title"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-1 align-self-center mt-3"},[_c('l-button',{attrs:{"type":"danger","size":"sm"},on:{"click":function($event){return _vm.removeItem(_vm.formData.teaching_methods, listItemIndex)}}},[_c('i',{staticClass:"fa fa-trash"})])],1)]}),_c('l-button',{staticClass:"mt-3",attrs:{"type":"success","size":"sm"},on:{"click":function($event){return _vm.addNewItem(_vm.formData.teaching_methods, {title: ''})}}},[_c('i',{staticClass:"fa fa-plus"})])],2)]),_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('After The Training List')))]),_c('div',{staticClass:"col-12"},[_vm._l((_vm.formData.after_training),function(listItem,listItemIndex){return [_c('div',{staticClass:"row team_list_items"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Item'))+" # "+_vm._s(listItemIndex + 1))]),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.after_trainingtitle","rules":"","name":"The Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{key:'title_'+listItemIndex,attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)},model:{value:(_vm.formData.after_training[listItemIndex].title),callback:function ($$v) {_vm.$set(_vm.formData.after_training[listItemIndex], "title", $$v)},expression:"formData.after_training[listItemIndex].title"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-1 align-self-center mt-3"},[_c('l-button',{attrs:{"type":"danger","size":"sm"},on:{"click":function($event){return _vm.removeItem(_vm.formData.after_training, listItemIndex)}}},[_c('i',{staticClass:"fa fa-trash"})])],1)]}),_c('l-button',{staticClass:"mt-3",attrs:{"type":"success","size":"sm"},on:{"click":function($event){return _vm.addNewItem(_vm.formData.after_training, {title: ''})}}},[_c('i',{staticClass:"fa fa-plus"})])],2)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Goals List')))]),_c('div',{staticClass:"col-12"},[_vm._l((_vm.formData.goals),function(listItem,listItemIndex){return [_c('div',{staticClass:"row team_list_items"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Item'))+" # "+_vm._s(listItemIndex + 1))]),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.goalstitle","rules":"","name":"The Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{key:'title_'+listItemIndex,attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)},model:{value:(_vm.formData.goals[listItemIndex].title),callback:function ($$v) {_vm.$set(_vm.formData.goals[listItemIndex], "title", $$v)},expression:"formData.goals[listItemIndex].title"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-1 align-self-center mt-3"},[_c('l-button',{attrs:{"type":"danger","size":"sm"},on:{"click":function($event){return _vm.removeItem(_vm.formData.goals, listItemIndex)}}},[_c('i',{staticClass:"fa fa-trash"})])],1)]}),_c('l-button',{staticClass:"mt-3",attrs:{"type":"success","size":"sm"},on:{"click":function($event){return _vm.addNewItem(_vm.formData.goals, { title: ''})}}},[_c('i',{staticClass:"fa fa-plus"})])],2)]),_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Scientific Programmes')))]),_c('div',{staticClass:"col-12"},[_vm._l((_vm.formData.scientific_programmes),function(listItem,listItemIndex){return [_c('div',{staticClass:"row team_list_items"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Item'))+" # "+_vm._s(listItemIndex + 1))]),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.scientific_programmestitle","rules":"","name":"The Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{key:'title_'+listItemIndex,attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)},model:{value:(_vm.formData.scientific_programmes[listItemIndex].title),callback:function ($$v) {_vm.$set(_vm.formData.scientific_programmes[listItemIndex], "title", $$v)},expression:"formData.scientific_programmes[listItemIndex].title"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"fr.scientific_programmes_subtitle","rules":"","name":"The Subtitle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{key:'subtitle_'+listItemIndex,attrs:{"type":"text","error":failed ? errors[0]: null,"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Subtitle')+' '+(listItemIndex+1)},model:{value:(_vm.formData.scientific_programmes[listItemIndex].subtitle),callback:function ($$v) {_vm.$set(_vm.formData.scientific_programmes[listItemIndex], "subtitle", $$v)},expression:"formData.scientific_programmes[listItemIndex].subtitle"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-1 align-self-center mt-3"},[_c('l-button',{attrs:{"type":"danger","size":"sm"},on:{"click":function($event){return _vm.removeItem(_vm.formData.scientific_programmes, listItemIndex)}}},[_c('i',{staticClass:"fa fa-trash"})])],1)]}),_c('l-button',{staticClass:"mt-3",attrs:{"type":"success","size":"sm"},on:{"click":function($event){return _vm.addNewItem(_vm.formData.scientific_programmes, { title: '', subtitle: ''})}}},[_c('i',{staticClass:"fa fa-plus"})])],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }