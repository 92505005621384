<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">{{ $store.getters['languageMixin/getStringFromLanguage'](lang.label) }} {{ $store.getters['languageMixin/getStringFromLanguage']('Section') }}</h5>


      <!--          Title-->
      <div class="row">
        <div class="col-md-12">
          <ValidationProvider
            vid="fr.title"
            rules=""
            name="The Title"
            v-slot="{ passed, failed,errors }">
            <fg-input type="text"
                      :error="failed ? errors[0]: null"
                      :label="$store.getters['languageMixin/getStringFromLanguage']('Title')"
                      name="title"
                      fou
                      v-model="formData.title">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>

      <!--          Brief-->
      <div class="form-group">
        <label>{{ this.$store.getters['languageMixin/getStringFromLanguage']('Brief') }}</label>
        <editor
          v-model="formData.brief"
          :api-key="editorKey"
          :init='editorConfig'
        />
      </div>

      <!--  Content-->
      <div class="form-group">
        <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Content') }}</label>
        <editor
          v-model="formData.content"
          :api-key="editorKey"
          :init='editorConfig'
        />
      </div>

      <!--  Prerequisites-->
      <div class="row">
        <div class="col-md-12">
          <ValidationProvider
            vid="fr.prerequisites"
            rules=""
            name="The Prerequisites"
            v-slot="{ passed, failed,errors }">
            <fg-text type="text"
                     :error="failed ? errors[0]: null"
                     :label="$store.getters['languageMixin/getStringFromLanguage']('Prerequisites')"
                     name="prerequisites"
                     fou
                     v-model="formData.prerequisites">
            </fg-text>
          </ValidationProvider>
        </div>
      </div>

       <!-- Extra fields -->

       <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.audience_target"
                rules=""
                name="The Audience"
                v-slot="{ passed, failed,errors }">
                <fg-text type="text"
                         :error="failed ? errors[0]: null"
                         :label="$store.getters['languageMixin/getStringFromLanguage']('Audience')"
                         name="audience_target"
                         fou
                         v-model="formData.audience_target">
                </fg-text>
              </ValidationProvider>
            </div>
          </div>

          <div class="form-group">
            <label>{{ this.$store.getters['languageMixin/getStringFromLanguage']('Notes') }}</label>
            <editor
              v-model="formData.notes"
              :api-key="editorKey"
              :init='editorConfig'
            />
          </div>



          <!-- end extra fields -->

      <!--          Lists-->
      <div class="row">

        <!--           Teaching Methods list items-->
        <div class="col-md-6">
          <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Teaching Methods List') }}</label>
          <div class="col-12">
            <template class="row" v-for="(listItem,listItemIndex) in formData.teaching_methods">
              <div class="row team_list_items">
                <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Item') }} # {{ listItemIndex + 1 }}</label>
                <!--                list item title-->
                <div class="col-md-12">
                  <ValidationProvider
                    vid="fr.teaching_methodstitle"
                    rules=""
                    name="The Title"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :key="'title_'+listItemIndex"
                              :error="failed ? errors[0]: null"
                              :label="$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)"
                              v-model="formData.teaching_methods[listItemIndex].title">
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-1 align-self-center mt-3">
                <l-button
                  @click="removeItem(formData.teaching_methods, listItemIndex)"
                  type="danger" size="sm">
                  <i class="fa fa-trash"></i>
                </l-button>
              </div>
            </template>
            <l-button
              class="mt-3"
              type="success"
              @click="addNewItem(formData.teaching_methods, {title: ''})"
              size="sm">
              <i class="fa fa-plus"></i>
            </l-button>
          </div>
        </div>

        <!--            Volunteering list items-->
        <div class="col-md-6">
          <label>{{ $store.getters['languageMixin/getStringFromLanguage']('After The Training List') }}</label>
          <div class="col-12">
            <template class="row" v-for="(listItem,listItemIndex) in formData.after_training">
              <div class="row team_list_items">
                <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Item') }} # {{ listItemIndex + 1 }}</label>
                <!--                list item title-->
                <div class="col-md-12">
                  <ValidationProvider
                    vid="fr.after_trainingtitle"
                    rules=""
                    name="The Title"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :key="'title_'+listItemIndex"
                              :error="failed ? errors[0]: null"
                              :label="$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)"
                              v-model="formData.after_training[listItemIndex].title">
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-1 align-self-center mt-3">
                <l-button
                  @click="removeItem(formData.after_training, listItemIndex)"
                  type="danger" size="sm">
                  <i class="fa fa-trash"></i>
                </l-button>
              </div>
            </template>
            <l-button
              class="mt-3"
              type="success"
              @click="addNewItem(formData.after_training, {title: ''})"
              size="sm">
              <i class="fa fa-plus"></i>
            </l-button>
          </div>
        </div>
      </div>

      <div class="row">
        <!--        Goals list items-->
        <div class="col-md-6">
          <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Goals List') }}</label>
          <div class="col-12">
            <template class="row" v-for="(listItem,listItemIndex) in formData.goals">
              <div class="row team_list_items">
                <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Item') }} # {{ listItemIndex + 1 }}</label>
                <!--                list item title-->
                <div class="col-md-12">
                  <ValidationProvider
                    vid="fr.goalstitle"
                    rules=""
                    name="The Title"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :key="'title_'+listItemIndex"
                              :error="failed ? errors[0]: null"
                              :label="$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)"
                              v-model="formData.goals[listItemIndex].title">
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-1 align-self-center mt-3">
                <l-button
                  @click="removeItem(formData.goals, listItemIndex)"
                  type="danger" size="sm">
                  <i class="fa fa-trash"></i>
                </l-button>
              </div>
            </template>
            <l-button
              class="mt-3"
              type="success"
              @click="addNewItem(formData.goals, { title: ''})"
              size="sm">
              <i class="fa fa-plus"></i>
            </l-button>
          </div>
        </div>
        <!--        Scientific Programmes list items-->
        <div class="col-md-6">
          <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Scientific Programmes') }}</label>
          <div class="col-12">
            <template class="row" v-for="(listItem,listItemIndex) in formData.scientific_programmes">
              <div class="row team_list_items">
                <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Item')}} # {{ listItemIndex + 1 }}</label>
                <!--                list item title-->
                <div class="col-md-12">
                  <ValidationProvider
                    vid="fr.scientific_programmestitle"
                    rules=""
                    name="The Title"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :key="'title_'+listItemIndex"
                              :error="failed ? errors[0]: null"
                              :label="$store.getters['languageMixin/getStringFromLanguage']('Title')+' '+(listItemIndex+1)"
                              v-model="formData.scientific_programmes[listItemIndex].title">
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="col-md-12">
                  <ValidationProvider
                    vid="fr.scientific_programmes_subtitle"
                    rules=""
                    name="The Subtitle"
                    v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :key="'subtitle_'+listItemIndex"
                              :error="failed ? errors[0]: null"
                              :label="$store.getters['languageMixin/getStringFromLanguage']('Subtitle')+' '+(listItemIndex+1)"
                              v-model="formData.scientific_programmes[listItemIndex].subtitle">
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-sm-1 align-self-center mt-3">
                <l-button
                  @click="removeItem(formData.scientific_programmes, listItemIndex)"
                  type="danger" size="sm">
                  <i class="fa fa-trash"></i>
                </l-button>
              </div>
            </template>
            <l-button
              class="mt-3"
              type="success"
              @click="addNewItem(formData.scientific_programmes, { title: '', subtitle: ''})"
              size="sm">
              <i class="fa fa-plus"></i>
            </l-button>
          </div>
        </div>
      </div>


    </form>
  </ValidationObserver>
</template>
<script>
  import { Select, Option } from 'element-ui'
  import { extend } from "vee-validate";
  import { required, email, regex } from "vee-validate/dist/rules";
  import Editor from '@tinymce/tinymce-vue'
  import FormGroupTextArea from "@/components";

  extend("required", required);
  extend("email", email);
  extend("regex", regex);

  export default {
    components: {
      FormGroupTextArea,
      [Select.name]: Select,
      [Option.name]: Option,
      'editor': Editor
    },
    props: {
      lang: {
        type: Object
      },
      editMode: {
        type: Boolean,
        default: false
      },
      isSubmit: {
        type: Boolean,
        default: false
      },
      formData:{
        type: Object,
        default() {
          return {
            title: "",
            brief:"",
            content:"",
            prerequisites: '',
            teaching_methods: [],
            after_training: [],
            goals: [],
            scientific_programmes: [],
            audience_target : '',
            notes : ''
          }
        }
      },
    },
    data () {
      return {
        editorConfig: this.getEditorConfig(),
        editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          if (res){
            this.$emit('submit-step', this.formData, this.lang.value, this.isSubmit)
          }else{
            this.$emit('on-validated', res)
          }
          return res
        });
      },
      addNewItem(listItems, initObject) {
        listItems.push(initObject);
      },
      removeItem(listItems, index) {
        listItems.splice(index, 1);
      },
    }
  }
</script>
<style>
</style>
